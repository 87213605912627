
.cart-wrapper {
  .cart-header__features {
    border-inline-start: 1px solid var(--c-bg-4);
    border-inline-end: 1px solid var(--c-bg-4);
  }

  .items-container {
    border-inline-start: 1px solid var(--c-bg-4);
    border-inline-end: 1px solid var(--c-bg-4);
  }
  &.no-header .items-container {
    border-top: 1px solid var(--c-bg-4);
  }

  .cart-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none !important;
  }

  .cart-item-unit-controls {
    max-width: 150px;
  }

  .cart-item {
    border-bottom: 1px solid var(--c-gray-2);

    &:last-child {
      border-bottom: none;
    }

    &:nth-child(2n) {
      background-color: var(--c-gray-1);
    }
  }

  .cart-item__image {
    object-fit: contain;
    max-width: 15vw;
    max-height: 150px;
  }

  .cart-item__desc-row {
    //align-items: center;
  }

  &.break-xs {
    .cart-item__image {
      border: 1px solid var(--c-bg-4);
      max-width: 14vw;
      max-height: 130px;
    }
  }

  .pseudo-action-bar {
    border-top: 1px solid var(--c-bg-4);
  }
}
